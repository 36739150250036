import { Image } from '@pledge-earth/web-components';

import './Loading.scss';

interface Props {
  imageProps?: any;
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- eslint upgrade
export function Loading(props: any & Props) {
  return (
    <div className="Loading" {...props}>
      <Image
        src="/images/PledgeLoading.gif"
        alt="Loading"
        preview={false}
        className="Loading__image"
        {...(props.imageProps || {})}
      />
    </div>
  );
}
