/* eslint-disable @typescript-eslint/no-unused-vars */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { gtm } from '../../utils/gtm/gtm';
import type { CurrencyEnum, PlanItemPricePeriodEnum, ClientFunctionEnum } from '../../services/graphql/generated';

interface S3Object {
  location: string;
  eTag: string;
  bucket: string;
  key: string;
}

interface User {
  username: string | null;
  identities: string | null;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
  job_function: string | null;
  role: string | null;
  avatar: S3Object | null;
  language: string | null;
  source: string | null;
  account_state: string | null;
  user_id: string | null;
  user_intercomHash: string | null;
  client: string | null;
  client_id: string | null;
  client_public_id: string | null;
  client_function: ClientFunctionEnum | null;
  client_avatar: S3Object | null;
  client_logo: S3Object | null;
  client_is_created: boolean;
  client_domain: string | null;
  client_currency: CurrencyEnum | null;
  subscription: {
    status: string | null;
    is_active: boolean;
    trial_end_date: string | null;
    plan_id: string | null;
    plan_item_price_id: string | null;
    period: PlanItemPricePeriodEnum | null;
    entitlements: string[];
  };
  calculator: {
    preferred_public_api_key: string | null;
  };
  emission_import_configuration: {
    date_format: string | null;
    number_format: string | null;
  };
}

export interface UserState extends User {
  loading: boolean;
  badPassword: boolean;
  badVerificationCode: boolean;
  authenticated: boolean;
}

export const initialState: UserState = {
  username: null,
  first_name: null,
  last_name: null,
  role: null,
  job_function: null,
  email: null,
  user_id: null,
  language: null,
  avatar: null,
  client: null,
  client_id: null,
  client_public_id: null,
  client_function: null,
  client_avatar: null,
  client_logo: null,
  client_is_created: false,
  client_domain: null,
  user_intercomHash: null,
  client_currency: null,
  identities: null,
  source: null,
  account_state: null,
  subscription: {
    status: null,
    is_active: false,
    trial_end_date: null,
    plan_id: null,
    plan_item_price_id: null,
    period: null,
    entitlements: [],
  },
  calculator: {
    preferred_public_api_key: null,
  },
  emission_import_configuration: {
    date_format: null,
    number_format: null,
  },
  // extras
  authenticated: false,
  loading: false,
  badPassword: false,
  badVerificationCode: false,
};

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserState(state, action: PayloadAction<Partial<UserState>>) {
      const nextState = { ...state, ...action.payload };

      gtm.updateUserAttributes(action.payload as any, nextState as any);

      return nextState;
    },
    login(
      _state,
      _action: PayloadAction<{
        email: string;
        password: string;
        recaptcha: string;
      }>,
    ) {
      // do nothing - side effects in saga
    },
    loadCurrentAccount(_state, _action: PayloadAction<{ redirectTo?: string }>) {
      // do nothing - side effects in saga
    },
    passwordChanged(_state, _action: PayloadAction<{ oldPassword: string; newPassword: string }>) {
      // do nothing - side effects in saga
    },
    logout() {
      // do nothing - side effects in saga
    },
    forgotPassword(_state, _action: PayloadAction<{ email: string }>) {
      // do nothing - side effects in saga
    },
    passwordReset(
      _state,
      _action: PayloadAction<{
        email: string;
        password: string;
        passwordConfirmation: string;
        confirmationCode: string;
      }>,
    ) {
      // do nothing - side effects in saga
    },
    signUp(
      _state,
      _action: PayloadAction<{
        email: string;
        password: string;
        redirectTo: string | null;
        originTrackingData: {
          originUrlParameters: {
            msclkid?: string;
            gclid?: string;
            li_fat_id?: string;
          };
        };
      }>,
    ) {
      // do nothing - side effects in saga
    },
    signUpConfirmed(_state, _action: PayloadAction<{ email: string; code: string; url: string }>) {
      // do nothing - side effects in saga
    },
    resentConfirmSignUp(_state, _action: PayloadAction<{ email: string; pathname: string }>) {
      // do nothing - side effects in saga
    },
    inviteConfirmed(_state, _action: PayloadAction<{ email: string; password: string; token: string }>) {
      // do nothing - side effects in saga
    },
    mfaConfirmed(_state, _action: PayloadAction<{ code: string; session: string; email: string }>) {
      // do nothing - side effects in saga
    },
  },
});

export const {
  setUserState,
  login,
  loadCurrentAccount,
  passwordChanged,
  logout,
  forgotPassword,
  passwordReset,
  signUp,
  signUpConfirmed,
  resentConfirmSignUp,
  inviteConfirmed,
  mfaConfirmed,
} = actions;

export { reducer };
