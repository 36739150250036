import { Icons } from '@pledge-earth/web-components';

import { ErrorBase } from '../Base/ErrorBase';

const { WarningFilledIcon } = Icons;

/**
 * Last resort error component that does not use INTL
 */
export function LastResortError() {
  return (
    <ErrorBase icon={<WarningFilledIcon />} title="500: Unexpected error" description="Sorry, something went wrong." />
  );
}
