import { FormattedMessage } from 'react-intl';
import { BannerMessage, Strong, Text, twMerge } from '@pledge-earth/product-language';
import type { ReactNode } from 'react';

const strong = (chunks: ReactNode) => <Strong>{chunks}</Strong>;

export function TestModeBar({ className, isPublicPage = false }: { className?: string; isPublicPage?: boolean }) {
  return (
    <BannerMessage variant="warning" className={twMerge('justify-center rounded-none p-2', className)}>
      <Text>
        {isPublicPage ? (
          <FormattedMessage id="testModeBar.publicMessage" values={{ b: strong }} />
        ) : (
          <FormattedMessage id="testModeBar.message" values={{ b: strong }} />
        )}
      </Text>
    </BannerMessage>
  );
}
