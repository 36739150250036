import { useCallback } from 'react';
import type { NavigateOptions as ReactRouterNavigateOptions, To } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { checkIsTestModeRegex, useIsTestMode } from './useIsTestMode';

export function configurePathIfTestMode(testMode: boolean, pathname: string) {
  // no-op for relative and auth routes
  if (
    !pathname.startsWith('/') ||
    pathname.startsWith('/auth') ||
    pathname.startsWith('/40') ||
    pathname.startsWith('/50') ||
    pathname.startsWith('trial-expired')
  ) {
    return pathname;
  }

  if (testMode) {
    if (!checkIsTestModeRegex.test(pathname)) {
      if (pathname === '/') {
        return '/test';
      }

      return `/test${pathname}`;
    }

    return pathname;
  }

  if (checkIsTestModeRegex.test(pathname)) {
    if (pathname === '/test') {
      return '/';
    }

    return pathname.replace('/test', '');
  }

  return pathname;
}

type NavigateOptions = Omit<ReactRouterNavigateOptions, 'relative'> & {
  testMode?: boolean;
};

export type Navigate = (to: To, options?: NavigateOptions) => void;

export function configureToIfTestMode(testMode: boolean, to: To): To {
  if (typeof to === 'string') {
    return configurePathIfTestMode(testMode, to);
  }

  return {
    ...to,
    pathname: to.pathname ? configurePathIfTestMode(testMode, to.pathname) : undefined,
  };
}

/**
 * Custom hook for navigation that preserves whether the user is in test mode.
 * History delta navigation `navigate(-1)` and [relative path](https://reactrouter.com/en/main/components/link#relative)
 * routing is not supported. React router's `useNavigate`
 * should be used for these cases.
 *
 * Additionally, `options` has been extended with a `testMode` option in the rare
 * case where the caller needs to explicitly declare test mode.
 *
 * E.g. `/test/profile` -> `navigateWithTestMode('/emissions')` -> `'/test/emissions'`
 */
export function useNavigateWithTestMode(): Navigate {
  const navigate = useNavigate();
  const isTestMode = useIsTestMode();

  const customNavigate = useCallback(
    (to: To, options?: NavigateOptions) => {
      const testModeOverride = options?.testMode !== undefined ? options.testMode : isTestMode;
      navigate(configureToIfTestMode(testModeOverride, to), options);
    },
    [isTestMode, navigate],
  );

  return customNavigate as Navigate;
}
