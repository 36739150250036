import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Matches /test but not /test-* (to avoid mathcing /test-data)
export const checkIsTestModeRegex = /^\/test(?!-)/;

function isTestMode(pathname: string): boolean {
  return checkIsTestModeRegex.test(pathname);
}

export function useIsTestMode() {
  const { pathname } = useLocation();
  const res = useMemo(() => isTestMode(pathname), [pathname]);
  return res;
}
