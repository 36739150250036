import type { LinkProps as PledgeLinkProps } from '@pledge-earth/product-language';
import { Link as PledgeLink } from '@pledge-earth/product-language';
import { Icons } from '@pledge-earth/web-components';

const { ExternalLinkIcon } = Icons;

type ExternalLinkProps = PledgeLinkProps<'a'> & {
  noExternalIcon?: boolean;
};

export function ExternalLink({ children, noExternalIcon = false, ...props }: ExternalLinkProps) {
  return (
    <PledgeLink elementType="a" target="_blank" rel="noopener noreferrer" {...props}>
      {children} {!noExternalIcon && <ExternalLinkIcon className="ml-1" />}
    </PledgeLink>
  );
}
