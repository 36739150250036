import store from 'store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const DEFAULT_SETTINGS = {
  title: 'Pledge',
  theme: 'light',
  locale: 'en-GB',
  isSidebarOpen: false,
  isSupportChatOpen: false,
  isMobileView: false,
  isMobileMenuOpen: false,
  isMenuCollapsed: false,
  isPreselectedOpen: false,
  routerAnimation: 'slide-fadein-up',
  isAuthTopbar: true,
  leftMenuWidth: 248,
  isTopbarSeparated: false,
  layoutFooter: false,
  customMenu: true,
  isProductionData: true,
};

type SettingsState = {
  [Key in keyof typeof DEFAULT_SETTINGS as Key]: (typeof DEFAULT_SETTINGS)[Key];
};

const { actions, reducer } = createSlice({
  name: 'settings',
  initialState: () => {
    const settings: Record<any, any> = {};
    Object.keys(DEFAULT_SETTINGS).forEach((key) => {
      const item = store.get(`app.settings.${key}`);
      settings[key] = typeof item !== 'undefined' ? item : DEFAULT_SETTINGS[key as keyof typeof DEFAULT_SETTINGS];
    });

    // slightly dangerous cast
    return settings as SettingsState;
  },
  reducers: {
    settingChanged(
      state,
      action: PayloadAction<{
        setting: keyof SettingsState;
        value: SettingsState[keyof SettingsState];
      }>,
    ) {
      return {
        ...state,
        [action.payload.setting]: action.payload.value,
      };
    },
    localeChanged(state, action: PayloadAction<{ language: string | null | undefined }>) {
      if (!action.payload.language) {
        return state;
      }

      return { ...state, locale: action.payload.language };
    },
  },
});

export const { settingChanged, localeChanged } = actions;

export { reducer };
